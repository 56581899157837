import React, { Fragment, useEffect, useState } from 'react';

import { FeatureNameEnum, logVariant, useFeatureFlag } from 'hooks/useFeatureFlags';
import { useTranslation } from 'hooks/useTypedTranslation';
import { IUser } from 'interfaces';
import * as speechify from 'lib/speechify';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'store';
import { actions as authActions } from 'store/auth';
import { actions as toastActions } from 'store/toast';
import { twMerge } from 'tailwind-merge';
import { isItemOrSharePage } from 'utils';
import { getSource, logSegmentEvent } from 'utils/analytics';

import { Menu, Transition } from '@headlessui/react';

import { useNavigate } from '../../hooks/useNavigate';
import UserAvatar from './Avatar';
import { FeatureRequestModal } from './FeatureRequestModal';

type HeaderAvatarProps = {
  isInstantListening: boolean | undefined;
  size: number;
};

const HeaderAvatar: React.FC<HeaderAvatarProps> = props => {
  const { isInstantListening, size } = props;
  const dispatch = useDispatch();
  const router = useRouter();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const currentUser = useSelector<IUser>(state => state.auth.user);

  const [featureRequestOpen, setFeatureRequestOpen] = useState(false);

  // feature flags
  const { variant: requestAFeatureVariant, isLoading: isRequestAFeatureVariantLoading } = useFeatureFlag(FeatureNameEnum.REQUEST_A_FEATURE_WEB_APP);

  useEffect(() => {
    if (!isRequestAFeatureVariantLoading) {
      logVariant('requestAFeatureWebApp', requestAFeatureVariant);
    }
  }, [requestAFeatureVariant, isRequestAFeatureVariantLoading]);

  const showRequestAFeatureOption = !isRequestAFeatureVariantLoading && requestAFeatureVariant !== 'control';

  const handleHelpClick = () => {
    window.open('https://help.speechify.com/en/collections/2750947-speechify-for-chrome', '_blank');
    logSegmentEvent('web_app_help_opened', { source: getSource(router.pathname) });
  };

  const handleLogoutClick = () => {
    logSegmentEvent('web_app_sign_out_pressed', { source: getSource(router.pathname) });
    dispatch(authActions.logout());
  };

  const handleFeatureRequestCancel = () => {
    setFeatureRequestOpen(false);
  };

  const handleFeatureRequestClick = () => {
    setFeatureRequestOpen(true);
    logSegmentEvent('web_app_feature_request_clicked', { source: getSource(router.pathname) });
  };

  const handleFeatureRequestSubmit = async (data: { email: string; message: string }) => {
    setFeatureRequestOpen(false);

    // @ts-expect-error TS(2531): Object is possibly 'null'.
    const idToken = await speechify.auth.currentUser.getIdToken();

    await fetch('/api/email/sendfeaturerequest', {
      method: 'POST',
      headers: { 'Content-type': 'application/json; charset=UTF-8', Authorization: `Bearer ${idToken}` },
      body: JSON.stringify({ email: data.email || currentUser.email, message: data.message })
    });

    dispatch(
      toastActions.add({
        description: t('Thank you for your feature request.'),
        type: 'success'
      })
    );
  };

  const handlePrivacyClick = () => {
    window.open('https://speechify.com/privacy', '_blank');
    logSegmentEvent('web_app_privacy_opened', { source: getSource(router.pathname) });
  };

  const handleSettingsClick = () => {
    logSegmentEvent('web_app_settings_opened', { source: getSource(router.pathname) });
    navigate('/settings');
  };

  const handleTermsClick = () => {
    window.open('https://speechify.com/terms', '_blank');
    logSegmentEvent('web_app_terms_opened', { source: getSource(router.pathname) });
  };

  return (
    <Fragment>
      <Menu as="div" className="relative">
        {({ open }) => (
          <Fragment>
            <Menu.Button
              className={twMerge(
                'flex',
                isInstantListening || isItemOrSharePage() ? 'bg-glass-0 dark:bg-glass-700' : 'bg-glass-0',
                'rounded-full',
                'focus:outline-none'
              )}
            >
              <span className="sr-only">{t('Open user menu')}</span>
              <UserAvatar
                size={size}
                // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
                email={currentUser.email}
                // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
                name={currentUser.displayName}
              />
            </Menu.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className={twMerge(
                  'absolute',
                  'right-0',
                  'z-[1000]',
                  'mt-5',
                  'origin-top-right',
                  isInstantListening || isItemOrSharePage() ? 'bg-glass-0 dark:bg-glass-700' : 'bg-glass-0',
                  'divide-y',
                  isInstantListening || isItemOrSharePage() ? 'divide-glass-300 dark:divide-glass-600' : 'divide-glass-300',
                  'rounded-md',
                  'shadow-md',
                  'w-68',
                  'ring-1',
                  isInstantListening || isItemOrSharePage() ? 'ring-glass-300 dark:ring-glass-600' : 'ring-glass-300',
                  'ring-opacity-5',
                  'focus:outline-none'
                )}
              >
                <Fragment>
                  <div>
                    <Menu.Item key="menu-header">
                      {/* ESLint: 'active' is defined but never used */}
                      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                      {({ active }) => (
                        <Fragment>
                          <button
                            className={twMerge('group', 'flex', 'w-full', 'items-center', 'px-4', 'py-2', 'text-left', 'text-sm')}
                            onClick={handleSettingsClick}
                          >
                            <UserAvatar
                              // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
                              email={currentUser?.email}
                              // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
                              name={currentUser?.displayName}
                              size={42}
                            />
                            <div
                              className={twMerge(
                                'ml-2',
                                'text-sm',
                                'font-medium',
                                'truncate',
                                isInstantListening || isItemOrSharePage() ? 'text-glass-700 dark:text-glass-0' : 'text-glass-700'
                              )}
                            >
                              {currentUser?.displayName}
                              <p className="font-normal text-glass-400">{currentUser?.email}</p>
                            </div>
                          </button>
                        </Fragment>
                      )}
                    </Menu.Item>
                  </div>

                  <div>
                    <Menu.Item key="settings">
                      {({ active }) => (
                        <button
                          className={twMerge(
                            !(isInstantListening || isItemOrSharePage()) && active && 'bg-glass-200 text-glass-700',
                            !(isInstantListening || isItemOrSharePage()) && !active && 'text-glass-700',
                            (isInstantListening || isItemOrSharePage()) && active && 'bg-glass-200 text-glass-700 dark:bg-glass-800 dark:text-glass-0',
                            (isInstantListening || isItemOrSharePage()) && !active && 'text-glass-700 dark:text-glass-0',
                            'group',
                            'flex',
                            'w-full',
                            'items-center',
                            'px-4',
                            'py-2',
                            'text-sm'
                          )}
                          onClick={handleSettingsClick}
                        >
                          {t('Settings')}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item key="help">
                      {({ active }) => (
                        <button
                          className={twMerge(
                            !(isInstantListening || isItemOrSharePage()) && active && 'bg-glass-200 text-glass-700',
                            !(isInstantListening || isItemOrSharePage()) && !active && 'text-glass-700',
                            (isInstantListening || isItemOrSharePage()) && active && 'bg-glass-200 text-glass-700 dark:bg-glass-800 dark:text-glass-0',
                            (isInstantListening || isItemOrSharePage()) && !active && 'text-glass-700 dark:text-glass-0',
                            'group',
                            'flex',
                            'w-full',
                            'items-center',
                            'px-4',
                            'py-2',
                            'text-sm'
                          )}
                          onClick={handleHelpClick}
                        >
                          {t('Help')}
                        </button>
                      )}
                    </Menu.Item>
                    {showRequestAFeatureOption && speechify.isPremium(currentUser) && (
                      <Menu.Item key="feature-request">
                        {({ active }) => (
                          <button
                            className={twMerge(
                              !(isInstantListening || isItemOrSharePage()) && active && 'bg-glass-200 text-glass-700',
                              !(isInstantListening || isItemOrSharePage()) && !active && 'text-glass-700',
                              (isInstantListening || isItemOrSharePage()) && active && 'bg-glass-200 text-glass-700 dark:bg-glass-800 dark:text-glass-0',
                              (isInstantListening || isItemOrSharePage()) && !active && 'text-glass-700 dark:text-glass-0',
                              'group',
                              'flex',
                              'w-full',
                              'items-center',
                              'px-4',
                              'py-2',
                              'text-sm'
                            )}
                            onClick={handleFeatureRequestClick}
                          >
                            {t('Request a feature')}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    <Menu.Item key="sign-out">
                      {({ active }) => (
                        <button
                          className={twMerge(
                            !(isInstantListening || isItemOrSharePage()) && active && 'bg-glass-200 text-glass-700',
                            !(isInstantListening || isItemOrSharePage()) && !active && 'text-glass-700',
                            (isInstantListening || isItemOrSharePage()) && active && 'bg-glass-200 text-glass-700 dark:bg-glass-800 dark:text-glass-0',
                            (isInstantListening || isItemOrSharePage()) && !active && 'text-glass-700 dark:text-glass-0',
                            'group',
                            'flex',
                            'w-full',
                            'items-center',
                            'px-4',
                            'py-2',
                            'text-sm'
                          )}
                          onClick={handleLogoutClick}
                        >
                          {t('Sign Out')}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="flex whitespace-nowrap">
                    <Menu.Item key="privacy">
                      {({ active }) => (
                        <button
                          className={twMerge(
                            !(isInstantListening || isItemOrSharePage()) && active && 'bg-glass-200 text-glass-700',
                            !(isInstantListening || isItemOrSharePage()) && !active && 'text-glass-400',
                            (isInstantListening || isItemOrSharePage()) && active && 'bg-glass-200 text-glass-700 dark:bg-glass-800 dark:text-glass-0',
                            (isInstantListening || isItemOrSharePage()) && !active && 'text-glass-400',
                            'group',
                            'flex',
                            'w-full',
                            'items-center',
                            'px-4',
                            'py-2',
                            'text-xs'
                          )}
                          onClick={handlePrivacyClick}
                        >
                          {t('Privacy Policy')}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item key="terms">
                      {({ active }) => (
                        <button
                          className={twMerge(
                            !(isInstantListening || isItemOrSharePage()) && active && 'bg-glass-200 text-glass-700',
                            !(isInstantListening || isItemOrSharePage()) && !active && 'text-glass-400',
                            (isInstantListening || isItemOrSharePage()) && active && 'bg-glass-200 text-glass-700 dark:bg-glass-800 dark:text-glass-0',
                            (isInstantListening || isItemOrSharePage()) && !active && 'text-glass-400',
                            'group',
                            'flex',
                            'w-full',
                            'items-center',
                            'px-4',
                            'py-2',
                            'text-xs'
                          )}
                          onClick={handleTermsClick}
                        >
                          {t('Terms & Conditions')}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Fragment>
              </Menu.Items>
            </Transition>
          </Fragment>
        )}
      </Menu>

      {featureRequestOpen && <FeatureRequestModal onCancel={handleFeatureRequestCancel} onSubmit={handleFeatureRequestSubmit} />}
    </Fragment>
  );
};

export default HeaderAvatar;
